.top-nav-right {
  display: flex;
  float: right;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: white;
}

.top-nav-left {
  display: flex;
  float: left;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: white;
}

.avatar {
  background-color: white;
  cursor: pointer;
}

.avatarContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  transition: background-color 0.25s;
}

.avatarContainer:hover {
  background-color: #1890ff;
}

.logo-text {
  font-size: 15px;
  font-weight: bold;
}

.notification {
  position: absolute !important;
  top: 0 !important;
  right: 0px;
  border: 1px solid #80808026;
  z-index: 100;
  height: 89vh;
  flex-wrap: wrap;
  padding: 0px !important;
}

.li-item {
  overflow-wrap: normal;
  border-bottom: 1px solid #80808026 !important;
  border-radius: 5px !important;
  padding: 0px !important;
}
.cancel-button {
  object-fit: scale-down;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.notification-header {
  background: #211d70;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  cursor: default;
}

.notification-body {
  overflow-y: auto;
  height: 92vh;
}

.notification-clear {
  margin-left: 20%;
  cursor: pointer;
}

.nav-items {
  color: white;
  margin-right: 20px;
  margin-left: 20px;
}
.card-not-body {
  display: flex;
  flex-direction: column;
}
.card-not-header {
  display: flex;
  justify-content: space-between;
}
.header-text-not {
  font-weight: bold;
  font-size: 16px;
  width: 90%;
}
.notification-text {
  color: #8c8c8c;
  font-size: 13px;
}
.time-not {
  color: #bfbfbf;
  font-size: small;
  display: flex;
  justify-content: flex-start;
  padding-top: 2%;
  font-weight: lighter;
}

.navbar-button {
  color: #262c6f;
  border: none;
  font-weight: bold;
}
