.order-desc {
  padding: 0 2%;
}

.order-desc .ant-descriptions-item-label,
.order-desc .ant-descriptions-item-content {
  font-size: 18px;
}

.return-btn {
  width: 30%;
  margin: 0 35%;
}

.order-card {
  padding: 10px;
}
